<template>
<div class="container hideimgmob">
  <div class="row marg-0 marg-0-40 backgroundclr-f8f8 padd-t-20 padd-b-18 lato-font ">
    <div class="col-3">
      <img class="" src="..\assets\amogalogo.svg" alt="" />
      <div class="marg-t-10 fsize14"> <img class="phoneimg" src="..\assets\Call icon.svg" alt="">+91 98 8441 2345</div>
      <span class="fsize10 clr9b9b">2010-2021, AMOGA ALGOS @ANALYTICS</span>
      <div class="fsize10 clr9b9b">All rights reserved.</div>
    </div>
    <div class="col-3">
      <div class="fsize12 fw-bold">Company</div>
      <div class="fsize12 marg-t-10 clr9b9b">About</div>
      <div class="fsize12 marg-t-10 clr9b9b">Products</div>
      <div class="fsize12 marg-t-10 clr9b9b">Pricing</div>
      <div class="fsize12 marg-t-10 clr9b9b">Referral programme</div>
      <div class="fsize12 marg-t-10 clr9b9b">Careers</div>
    </div>
    <div class="col-3">
      <div class="fsize12 fw-bold">Support</div>
      <div class="fsize12 marg-t-10 clr9b9b">Contact</div>
      <div class="fsize12 marg-t-10 clr9b9b">Support Portal</div>
      <div class="fsize12 marg-t-10 clr9b9b">D-connect blog</div>
      <div class="fsize12 marg-t-10 clr9b9b">List of charges</div>
      <div class="fsize12 marg-t-10 clr9b9b">Downloads & resources</div>
    </div>
    <div class="col-3">
      <div class="fsize12 fw-bold">Account</div>
      <div class="fsize12 marg-t-10 clr9b9b">open an account</div>
      <div class="fsize12 marg-t-10 clr9b9b">Fund transfer</div>
      <div class="fsize12 marg-t-10 clr9b9b">60 day challenge</div>
    </div>
  </div>
</div>
</template>

<script>
export default {};
</script>

<style>

/* .padd-t-20 {
  padding-top: 20px !important;
} */


</style>