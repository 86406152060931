import Vue from 'vue'
import VueRouter from 'vue-router'
import content from '../views/content.vue'
import login from '../views/login.vue'
import header from '../views/header.vue'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/footer',
  //   name: 'footer',
  //   component: footer
  // },

  {
    path: "",
    name: "content",
    component: content,
    children: [
      {
        path: '',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/login.vue'),
      },
      {
        path: '/otp',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "otp" */ '../views/otp.vue'),
      },

      {
        path: '/details',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "details" */ '../views/details.vue'),
      },
      {
        path: '/pan',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "pan" */ '../views/pan.vue'),
      },
      {
        path: '/payaccount',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "payaccount" */ '../views/payaccount.vue'),
      },
      {
        path: '/aadhaarkyc',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "aadhaarkyc" */ '../views/aadhaarkyc.vue'),
      },
      {
        path: '/profile',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile.vue'),
      },
      {
        path: '/bank',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "bank" */ '../views/bank.vue'),
      },
      {
        path: '/bankaccount-1',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "bankaccount-1" */ '../views/bankaccount-1.vue'),
      },
      {
        path: '/webcam',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "webcam" */ '../views/webcam.vue'),
      },
      {
        path: '/documents',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "documents" */ '../views/documents.vue'),
      },
      {
        path: '/mobilenumber',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "documents" */ '../views/mobilenumber.vue'),
      },
      {
        path: '/registermobile',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "registermobile" */ '../views/registermobile.vue'),
      },



    ]
  },

  // {
  //   path: '/footer',
  //   name: 'footer',
  // route level code-splitting
  // this generates a separate chunk (footer.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "footer" */ '../views/footer.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
