<template>
<div  class="container">
    <div class="wholepage lato-font padd-0-mob">
      <img class="amogologo" src="..\assets\amogalogo.svg" alt="" />
      <span class="header-vLine fsize14 hideimgmob"></span>
      <span class="padd0-18 fsize14 hideimgmob">About</span>
      <span class="padd0-18 fsize14 hideimgmob">Products</span>
      <span class="padd0-18 fsize14 hideimgmob">Pricing</span>
      <span class="padd0-18 fsize14 hideimgmob">Support</span>
     
      <span class="f-right">
        <!-- <img class="bellicon" src="..\assets\SearchIcon.svg" alt="" />
        <img
          class="bellicon margin-l-24"
          src="..\assets\Calendaricon.svg"
          alt=""
        />
        <img class="bellicon bellimg" src="..\assets\bellicon.svg" alt="" />
        <span class="header-vLine fsize14"></span> -->
        <img
          class="bellicon margin-l-32 hideimgmob"
          src="..\assets\Profileicon.svg"
          alt=""
        />
        <span class="fsize14 margin-l-12 hideimgmob">Guest</span>
      </span>
    </div>
    </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style>
.amogologo {
  width: 95px;
  height: 27px;
  float: left;
}
.header-vLine {
  border-left: 1px solid #e7e7e7 !important;
  height: 24px !important;
  margin: 0px 12px;
}
.wholepage {
  padding: 17px 0px 12px 0px;
  line-height: 28px;
  /* z-index: 1 !important; */
}
.bellicon {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}
.bellimg {
  margin: 0px 20px 0px 30px;
}

</style>
