<template>
  <v-app class="lato-font ">
    <div class="">
    <div class="row marg-0 padd-t-128 ">
      <div class="col-md-7 col-sm-12 col-12 padd-0">
        <div class="h-282">
          <div class=" marg-0 align-center">
            <div class="">
              <div>
                <span class="fsize24 marg-0 clr-000000"> Signup now</span>
              </div>
              <span class="fsize16-afafaf padd-6"
                >Or track your existing appilication</span
              >
                <form @submit.prevent="loGin">
              <div class=" h-64 marg-t-18">
                <div class="l-height-16 marg-b-4 ">
                  <span class="fsize14 padd-l-8 clr-55585a">
                    Mobile Number
                  </span>
                </div>
                <div class="buttonIn">
                  <button id="clear">+91</button>
                  <input
                    type="text"
                    maxlength="10"
                    class=" h-40 bor-radius border-input fsize14 box-shado w-100per w-336"
                    placeholder="Your 10 digit mobile number"
                    autocomplete="off"
                    v-model="user.mobile"
                    id="mobile"
                    value="mobile"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    :class="{
                      'is-invalid': submitted && $v.user.mobile.$error,
                    }"
                  />

                  <div
                    v-if="submitted && $v.user.mobile.$error"
                    class="invalid-feedback marg-0 l-height-20"
                  >
                    <span
                      v-if="!$v.user.mobile.required"
                      class="validatemessage"
                      >Please enter your mobile Number</span
                    >
                    <span
                      v-if="!$v.user.mobile.minLength"
                      class="validatemessage"
                      >Please enter your valid mobile Number</span
                    >
                  </div>
                </div>
              </div>
                </form>
              <div class="marg-t-14 fsize12 clr9b9b">
                You will receive an OTP on your number
              </div>
            </div>
          </div>
          <div class="marg-t-38">
            <button
              class="btn-color fsize18 padd-0-16 bor-radius h-40 w-100per"
              @click="loGin()"
            >
              Continue
            </button>
          </div>

          <div class="marg-t-16">
            <span class="fsize16 fw-bold">Want to open an NRI account?</span>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-sm-12   padd-0 hideimgmob">
        <img class="desktopimg" src="..\assets\Desktop-login.svg" alt="" />
      </div>
    </div>
    </div>
  
  </v-app>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      user: {
        mobile: this.mobile,
      },
      mobile: null,
      submitted: true,
    };
  },
  validations: {
    user: {
      mobile: { required, minLength: minLength(10) },
    },
  },
  methods: {
    loGin() {
      this.submitted = true;
      //Error catch user id is invalid//
      this.loader = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$router.push("/otp");
    },
  },
};
</script>

<style>
/* input field css */

/* input with button */
.buttonIn {
  width: auto;
  position: relative;
}
.buttonIn input {
  margin: 0px;
  padding: 10px;
  padding-left: 50px !important;
  outline: none;
  height: 40px;
  border-color: solid 0.5px #9799a7 !important;
  border-radius: 3px;
}
.buttonIn button {
  position: absolute;
  top: 0;
  border-bottom-left-radius: 3px !important;
  border-top-left-radius: 3px !important;
  z-index: 2;
  height: 40px;
  cursor: pointer;
  color: black !important;
  font-weight: 500 !important;
  padding: 10px 0px 10px 16px;
  transform: translateX(0px);
  font-size: 14px;
}
</style>