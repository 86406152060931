<template>
  <v-app>
    <div class="page">
      <div id="headerpage">
        <topheader />
      </div>
      <div class="container" id="concept">
          <router-view />
      </div>
      <!-- class="container" -->
      <div class="hideimgmob"  id="footerpage">
        <alignfooter />
      </div>
    </div>
  </v-app>
</template>

<script>
import alignfooter from "../views/footer.vue";
import topheader from "../views/header.vue";
export default {
  components: {
    alignfooter,
    topheader
    
  },
};
</script>

<style>
.page {
  display: grid;
  height: 100vh;
  grid-template-columns: 56px 1fr 174px;
  grid-template-rows: 56px 1fr 174px;
  grid-template-areas:
    "headerpage headerpage headerpage"
    "concept concept concept"
    "footerpage footerpage footerpage";
}
#headerpage {
  grid-area: headerpage;
  height: 56px;
  /* left: 0px; */
  width: 100%;
  position: fixed;
  background-color: #fff;
border-bottom: 1px solid #eee;
  top: 0;
  z-index: 998 !important;
}
#concept {
  grid-area: concept;
  height: 100%;
}
#footerpage {
  grid-area: footerpage;
  height: 200px;
  left: 0px;
  width: 100%;
  background:  #f8f8f8;;
}
</style>